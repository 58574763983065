<template>
  <v-dialog
    content-class="signin-dialog"
    v-model="show"
    width="87%"
    max-width="500"
    @click:outside="$emit('close')"
  >
    <v-card class="signin-card">
      <v-card-text class="signin-card-text">
        <h3 class="signin-title">Sign in / Register</h3>

        <div v-if="showErrorMessage" class="error-message">
          {{ errorMessage }}
        </div>

        <div v-if="isNewUser" class="new-user-message">
          Thank you for registering. Please login using your credentials.
        </div>

        <div v-if="isForgotPasswordLinkSent" class="new-user-message">
          Password reset email was sent.
        </div>
        
        <component
          v-bind:is="signInActiveComponent"
          @open-register-form="openRegisterForm"
          @open-sign-in-form="openSignInForm"
          @open-forgot-password-form="openForgotPasswordForm"
          @login="loginUser"
          @register="registerUser"
          @reset-password="resetPassword"
        ></component>

        <p class="signin-agree">
          By continuing you agree to our <a href="/terms_and_conditions" target="_blank">T&Cs</a>. <br> 
          Please also check our <a href="/terms_and_conditions" target="_blank">Privacy Policy</a>.</p>
      </v-card-text>
    </v-card>

    <v-overlay :value="showLoader" z-index=12>
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
  </v-dialog>
</template>

<script>
  import axios from 'axios';
  import { mapGetters, mapActions } from 'vuex';
  import SignInForm from '@/components/Auth/SignInForm.vue';
  import RegisterForm from '@/components/Auth/RegisterForm.vue';
  import ForgotPasswordForm from '@/components/Auth/ForgotPasswordForm.vue';

  export default {
    name: 'SignInModal',
    props: {
      visible: {
        type: Boolean,
        default: false
      },
      active_component: {
        type: String,
        default: 'sign-in'
      }
    },
    components: {
      'sign-in': SignInForm,
      'register': RegisterForm,
      'forgot-password': ForgotPasswordForm
    },
    computed: {
      ...mapGetters([
        "userProfile",
        "selectedAddress",
        "isNewUser",
        "myFavourites",
        "signInActiveComponent"
      ]),
      show: {
        get () {
          return this.visible
        },
        set (value) {
           if (!value) {
            this.$emit('close');
          }
        }
      },
      isAddressSelected() {
        return this.selectedAddress != undefined && this.selectedAddress != null;
      }
    },
    data: () => ({
      showLoader: false,
      right_arrow_btn: "$vuetify.icons.right_arrow_btn",
      showErrorMessage: false,
      errorMessage: '',
      isForgotPasswordLinkSent: false
    }),
    methods: {
      ...mapActions([
        "login",
        "register",
        "getUserProfile",
        "getAddressList",
        "getMyOrders",
        "createNewAddress",
        "setIsNewUser",
        "setIsNewAddress",
        "getFavouriteDishes",
        "toggleFavouriteDish",
        "toggleFavourite",
        "setSignInActiveComponent"
      ]),
      loginUser(data) {
        this.showLoader = true;
        let email = data.username;
        
        this.login(data).then(() => {
          this.$gtag.event('login');

          fbq('trackCustom', 'Login');

          this.getUserProfile().then(() => {
 
            let reqData = {
              customerId: this.userProfile.id,
              email: email
            }
            this.getFavouriteDishes(reqData).then(() => {
              let myFavouritesStored = localStorage.getItem("myFavourites") ? JSON.parse(localStorage.getItem("myFavourites")) : [];
              let itemIds = [];
              if (myFavouritesStored.length != 0) {
                if (this.myFavourites.length != 0) {
                  myFavouritesStored.forEach((el) => {
                    let favObj = this.myFavourites.find(object => object.id === el.id);
                    if (favObj === undefined) {
                      itemIds.push(el.id);
                    }
                  });
                } else {
                  itemIds = myFavouritesStored.map((el) => {return el.id});
                }

                if (itemIds.length != 0) {
                  let payload = {
                    customerId: this.userProfile.id,
                    data: {
                      email: this.userProfile.email,
                      itemIds: itemIds,
                      enabled: true
                    }
                  }
                  this.toggleFavouriteDish(payload).then(() => {
                    itemIds.forEach((el) => {
                      this.toggleFavourite({dishId: el, enabled: true});
                    });
                  });
                }
              }

              if (this.isAddressSelected) {
                let addressPayload = {
                  email: email,
                  requestData: [this.selectedAddress]
                }
                this.createNewAddress(addressPayload).then(() => {
                  this.getAddressList(email).then(() => {
                    this.setIsNewUser(false);
                    this.setIsNewAddress(false);
                    this.showLoader = false;
                    this.$emit('close');
                  });
                });
              } else {
                this.getAddressList(email).then(() => {
                  let myOrdersPayload = {
                    customerId: this.userProfile.id,
                    email: this.userProfile.email
                  }
                  this.getMyOrders(myOrdersPayload).then(() => {
                    this.setIsNewUser(false);
                    this.showLoader = false;
                    this.$emit('close');
                  });
                });
              }
            });

              
          });
        }).catch(er => {
          this.errorMessage = er;
          this.showErrorMessage = true;
          this.showLoader = false;
        });
      },
      registerUser(data) {
        this.showLoader = true;
        this.showErrorMessage = false;

        this.register(data).then(() => {
          this.$gtag.event('sign_up');
          fbq('track', 'CompleteRegistration');
          
          this.setIsNewUser(true);
          this.showLoader = false;
          this.setSignInActiveComponent('sign-in');
        }).catch(er => {
          this.errorMessage = er;
          this.showErrorMessage = true;
          this.showLoader = false;
        });
      },
      openRegisterForm() {
        this.setSignInActiveComponent('register');
        this.showErrorMessage = false;
      },
      openSignInForm() {
        this.setSignInActiveComponent('sign-in');
        this.showErrorMessage = false;
      },
      openForgotPasswordForm() {
        this.setSignInActiveComponent('forgot-password');
        this.showErrorMessage = false;
      },
      resetPassword(email) {
        this.showLoader = true;
        let url = `${process.env.VUE_APP_BASE_URL}/accounts/We%20Cook/customers/passwordReset?email=${email}`;
        let payload = {email: email};

        axios.post(url, payload).then(res => {
          this.setSignInActiveComponent('sign-in');
          this.isForgotPasswordLinkSent = true;
          this.showLoader = false;
        }).catch(er => {
          console.log(er);
        });
      }
    }
  }
</script>

<style type="text/css">
  #my-app .signin-dialog,
  #my-app .signin-card {
    border-radius: 24px;
  }

  #my-app .signin-card-text {
    text-align: center;
    padding: 40px 30px 30px;
  }

  .signin-title {
    font-size: 21px;
    margin-bottom: 21px;
    text-align: left;
  }

  .signin-agree {
    margin-top: 13px;
    font-weight: 400;
    font-size: 12px;
    line-height: 13px;
  }

  p.signin-agree a {
    font-weight: 700;
    text-decoration: none;
    color: #001254;
  }

  .new-user-message {
    font-size: 14px;
    line-height: 16px;
    padding-bottom: 8px;
  }
</style>
