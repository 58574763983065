<template> 
  <v-form
    id="sign-in-form"
    ref="sign_in_form"
    v-model="validForm"
    lazy-validation
    @submit.prevent="loginUser"
  >
    <v-text-field
      v-model="email"
      autocomplete="username"
      :rules="emailRules"
      label="Email"
      background-color="#F2F2F2"
      class="my-text-field"
      color="#001254"
      solo
      flat
      rounded
      height="53"
      hide-details
    ></v-text-field>

    <v-text-field
      v-model="password"
      :rules="passwordRules"
      label="Password"
      type="password"
      background-color="#F2F2F2"
      class="my-text-field"
      color="#001254"
      solo
      flat
      rounded
      height="53"
      hide-details
    ></v-text-field>

    <v-row>
      <v-col cols="12">
        <v-btn
          block
          depressed
          class="title-12 signin-btn"
          :disabled="!isValidForm"
          type="submit"
          >
          Sign in
        </v-btn>
      </v-col>
      
      <v-col cols="12" class="pt-0">
        <v-btn
          text
          block
          class="title-12 register-btn"
          @click="$emit('open-register-form')"
        >
          <v-icon size="14"></v-icon>
          <v-spacer></v-spacer>
          Register
          <v-spacer></v-spacer>
          <v-icon size="14">{{right_arrow_btn}}</v-icon>
        </v-btn>
      </v-col>

      <v-col cols="12">
        <v-btn
          text
          class="forgot-password-btn"
          @click="$emit('open-forgot-password-form')"
        >
          Forgot Password?
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
  import { mapGetters } from 'vuex';

  export default {
    name: 'SignInForm',
    props: {
     visible: Boolean
    },
    computed: {
      ...mapGetters(["userProfile"]),
      show: {
        get () {
          return this.visible
        },
        set (value) {
           if (!value) {
            this.$emit('close');
          }
        }
      },
      isEmailEmpty() {
        return this.email === '';
      },
      isPasswordEmpty() {
        return this.password === '';
      },
      isValidForm() {
        return !this.isEmailEmpty && !this.isPasswordEmpty;
      }
    },
    data: () => ({
      validForm: true,
      email: '',
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      password: '',
      passwordRules: [
        value => !!value || 'Required.'
      ],
      right_arrow_btn: "$vuetify.icons.right_arrow_btn"
    }),
    methods: {
      loginUser() {
        let isValidForm = this.$refs.sign_in_form.validate();
        if (isValidForm) {
          let data = {
            username: this.email,
            password: this.password
          };
          this.$emit('login', data);
        }
      }
    }
  }
</script>

<style type="text/css">
  #sign-in-form .forgot-password-btn {
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
    color: #001254;
  }
</style>
