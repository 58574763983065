<template>
  <v-form id="forgot-password-form">
    <v-text-field
      v-model="email"
      autocomplete="username"
      :rules="emailRules"
      label="Email"
      background-color="#F2F2F2"
      class="my-text-field"
      color="#001254"
      solo
      flat
      rounded
      height="53"
      hide-details
    ></v-text-field>

    <v-row>
      <v-col cols="12">
        <v-btn
          block
          depressed
          class="title-12 signin-btn"
          :disabled="!isValidForm"
          @click="resetPassword"
          >
          Reset password
        </v-btn>
      </v-col>
      <v-col cols="12" class="pt-0">
        <v-btn
          block
          depressed
          class="title-12 register-btn"
          @click="$emit('open-sign-in-form')"
        >
          <v-icon size="14">{{left_arrow_btn}}</v-icon>
          <v-spacer></v-spacer>
          Sign in
          <v-spacer></v-spacer>
        </v-btn>
      </v-col>
    </v-row>
    <v-overlay :value="showLoader" z-index=12>
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
  </v-form>
</template>

<script>
  export default {
    name: 'ForgotPasswordForm',
    props: {
     visible: Boolean
    },
    computed: {
      show: {
        get () {
          return this.visible
        },
        set (value) {
           if (!value) {
            this.$emit('close');
          }
        }
      },
      isEmailEmpty() {
        return this.email === '';
      },
      isValidForm() {
        return !this.isEmailEmpty;
      }
    },
    data: () => ({
      showLoader: false,
      email: '',
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      left_arrow_btn: "$vuetify.icons.left_arrow_btn"
    }),
    methods: {
      resetPassword() {
        this.$emit('reset-password', this.email);
      }
    }
  }
</script>

<style type="text/css">

</style>
