<template> 
  <v-form
    id="register-form"
    ref="register_form"
    v-model="validForm"
    lazy-validation
    @submit.prevent="registerUser"
  >
    <v-text-field
      v-model="firstName"
      autocomplete="firstName"
      :rules="firstNameRules"
      label="First name *"
      background-color="#F2F2F2"
      class="my-text-field"
      color="#001254"
      solo
      flat
      rounded
      height="53"
    ></v-text-field>

    <v-text-field
      v-model="lastName"
      autocomplete="lastName"
      :rules="lastNameRules"
      label="Last name *"
      background-color="#F2F2F2"
      class="my-text-field"
      color="#001254"
      solo
      flat
      rounded
      height="53"
    ></v-text-field>

    <v-row>
      <v-col cols="3">
        <vue-country-code
          @onSelect="onSelect"
          :defaultCountry="'gr'"
        >
        </vue-country-code>
      </v-col>
      <v-col cols="9">
        <v-text-field
          v-model="phoneNumber"
          autocomplete="phoneNumber"
          :rules="phoneNumberRules"
          label="Phone number *"
          background-color="#F2F2F2"
          class="my-text-field"
          color="#001254"
          solo
          flat
          rounded
          height="53"
        ></v-text-field>
      </v-col>
    </v-row>
    <!-- <v-text-field
      v-model="phoneNumber"
      autocomplete="phoneNumber"
      :rules="phoneNumberRules"
      label="Phone number *"
      background-color="#F2F2F2"
      class="my-text-field"
      color="#001254"
      solo
      flat
      rounded
      height="53"
    ></v-text-field> -->

    <v-text-field
      v-model="email"
      autocomplete="username"
      :rules="emailRules"
      label="Email *"
      background-color="#F2F2F2"
      class="my-text-field"
      color="#001254"
      solo
      flat
      rounded
      height="53"
    ></v-text-field>

    <v-text-field
      v-model="password"
      autocomplete="new-password"
      :rules="passwordRules"
      label="Password *"
      type="password"
      background-color="#F2F2F2"
      class="my-text-field"
      color="#001254"
      solo
      flat
      rounded
      height="53"
    ></v-text-field>

    <v-row>
      <v-col cols="12">
        <v-btn
          text
          block
          class="title-12 register-btn"
          :disabled="!isValidForm"
          type="submit"
        >
          Register
        </v-btn>
      </v-col>
      <v-col cols="12" class="pt-0">
        <v-btn
          block
          depressed
          class="title-12 signin-btn"
          @click="$emit('open-sign-in-form')"
        >
          <v-icon size="14">{{left_arrow_btn}}</v-icon>
          <v-spacer></v-spacer>
          Sign in
          <v-spacer></v-spacer>
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
  import { mapGetters } from 'vuex';

  export default {
    name: 'RegisterForm',
    props: {
     visible: Boolean
    },
    computed: {
      ...mapGetters(["userProfile"]),
      show: {
        get () {
          return this.visible
        },
        set (value) {
           if (!value) {
            this.$emit('close');
          }
        }
      },
      isEmailEmpty() {
        return this.email === '';
      },
      isPasswordEmpty() {
        return this.password === '';
      },
      isValidForm() {
        return !this.isEmailEmpty && !this.isPasswordEmpty;
      }
    },
    data: () => ({
      validForm: true,
      email: '',
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      password: '',
      passwordRules: [
        value => !!value || 'Password is required.'
      ],
      left_arrow_btn: "$vuetify.icons.left_arrow_btn",
      firstName: '',
      firstNameRules: [
        v => !!v || 'Firstname is required',
      ],
      lastName: '',
      lastNameRules: [
        v => !!v || 'Lastname is required',
      ],
      phoneNumber: '',
      phoneNumberRules: [
        v => !!v || 'Phone number is required',
      ],
      accountId: process.env.VUE_APP_ACCOUNT_ID,
      dialCode: ''
    }),
    methods: {
      onSelect({name, iso2, dialCode}) {
        console.log(name, iso2, dialCode);
        this.dialCode = '+' + dialCode;
      },
      registerUser() {
        let isValidForm = this.$refs.register_form.validate();
        if (isValidForm) {
          let data = {
            email: this.email,
            lastName: this.lastName,
            firstName: this.firstName,
            password: this.password,
            phoneNumber: this.dialCode + this.phoneNumber,
            username: this.email + this.accountId,
            area: null,
            city: null,
            confirmed: true,
            firstOrder: true,
            invoiceInfo: null,
            languageCode: "en",
            locked: true,
            loyaltyPoints: null,
            receiveDiscountNews: false,
            receiveNewsLetters: false,
            registered: true,
            registrationCostCenterId: 6051,
            registrationCostCenterName: "wecook"
          }
          this.$emit('register', data);
        }
      }
    }
  }
</script>

<style type="text/css">
  #register-form .signin-btn {
    padding: 17px 34px;
    height: 53px;
    color: #001254;
    border-radius: 44px;
    background-color: rgb(242, 242, 242);
  }

  #register-form .register-btn {
    padding: 17px 34px;
    height: 53px;
    background-color: #001254;
    border-radius: 44px;
    color: #FFFFFF;
  }

  #register-form .my-text-field {
    margin: 0;
  }

  #register-form .my-text-field .v-input__slot {
    margin-bottom: 4px;
  }

  #register-form .my-text-field .v-text-field__details {
    min-height: 10px;
    margin-bottom: 4px;
  }

  #register-form .my-text-field .v-text-field__details .v-messages {
    min-height: 10px;
  }

  #register-form .my-text-field .v-text-field__details .v-messages__message {
    color: #FF3506;
    font-size: 10px;
  }

  #register-form .vue-country-select {
    height: 53px;
    width: 100%;
    border: none;
    background-color: #f2f2f2;
    border-radius: 50px;
  }

  #register-form .vue-country-select .dropdown:hover,
  #register-form .vue-country-select .dropdown.open {
    border-radius: 50px;
  }
</style>
